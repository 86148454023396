





















import useMisc from "@/use/misc";
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";

export default defineComponent({
  components: {
    OSurveyItem: () =>
      import("@/components/organisms/event/surveys/o-survey-item.vue"),
  },
  setup(_, { root }) {
    const { getSurveyType } = useMisc({ root });

    const state = reactive({
      headers: [
        {
          value: "index",
          text: root.$tc("layout.misc.orderNumber"),
          width: 1,
        },
        { value: "name", text: root.$tc("layout.misc.name") },
        {
          value: "questionsCount",
          text: root.$tc("event.panel.sidebar.survey.list.questionsNumber"),
          align: "right",
          width: 1,
        },
        {
          value: "completedSurveysCount",
          text: root.$tc(
            "event.panel.sidebar.survey.list.completedSurveysCount"
          ),
          align: "right",
          width: 1,
        },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      surveyDetails: false,
      surveyDetailsItem: [],
    });

    const surveyDetails = (item: any) => {
      state.surveyDetailsItem = Object.assign({}, item);
      state.surveyDetails = true;
    };

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const { page, itemsPerPage } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/survey/completed`, {
          params: { page, itemsPerPage },
        })
        .then(({ data: { surveys, total } }) => {
          state.empty = false;
          state.items = surveys;
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);
    watch(() => state.options, fetchData, { deep: true });

    return { state, fetchData, getSurveyType, surveyDetails };
  },
});
